import React from "react";
import styled, { keyframes } from "styled-components";

const fadeText = keyframes`
  from {
    color: #000;
  }

  to {
    color: #fff;
  }
`;

const TitleBox = styled.div`
  width: 100%;
  margin: 0;

  text-align: center;
  color: #fff;

  animation: ${fadeText} 5s once alternate;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & h1 {
    font-size: min(max(40px, 8vw), 150px);
    line-height: 1;

    text-align: center;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const Title = ({ text }) => (
  <TitleBox>
    <h1>A Delicate Sight</h1>
  </TitleBox>
);

export default Title;
