import React, { useEffect } from "react";

export const UpdateHeaderTextColor = ({
  updatedHeaderTextColor,
  setHeaderTextColor,
}) => {
  useEffect(() => {
    setHeaderTextColor(updatedHeaderTextColor);
  }, [updatedHeaderTextColor]);

  return <></>;
};
