import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);

  width: 30px;
  height: auto;

  cursor: pointer;

  & path {
    fill: #fff;
  }

  &:hover path {
    fill: #c4c4c4;
  }

  @media (max-width: 767px) {
    width: 20px;

    bottom: 30px;
  }

  @media (max-height: 500px) {
    display: none;
  }
`;

export const DownArrow = ({ onClick }) => (
  <Icon
    width="54"
    height="117"
    viewBox="0 0 54 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path d="M25.0169 0V107.033L0 92.4653V94.6121L27 117L54 94.6121V92.4653L28.983 107.033V0H25.0169Z" />
  </Icon>
);
