import React, { useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import styled, { keyframes } from "styled-components";
import { Helmet } from "react-helmet";
import { useInView } from "react-intersection-observer";
import sal from "sal.js";
import "../components/styles/sal.css";
import { mergePrismicPreviewData } from "gatsby-source-prismic";

// Context
import { HeaderTextColorConsumer } from "../components/context/header-text-color";

// Utils
import { UpdateHeaderTextColor } from "../components/utils/update-header-text-color";

// Components
import Title from "../components/text/title";
import { Video } from "../components/video/video";
import { DownArrow } from "../components/icons/down-arrow";

const fadeBackground = keyframes`
  from {
    background-color: #fff;
  }

  to {
    background-color: #000;
  }
`;

const Page = styled.div`
  margin: 10px 0 0 0;

  & .text p {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;

    margin: 0;

    max-width: 650px;
    margin: 0 0 0 auto;

    color: #fff;

    @media (max-width: 1000px) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  & .artwork p {
    margin-bottom: 0;
  }

  & .coming-soon-text p {
    margin: 3px 0 0 0;
  }

  & .artwork-text p {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;

    // max-width: 630px;
    margin: 0;

    color: #000;

    @media (max-width: 1000px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
`;

const Section = styled.section`
  min-height: 100vh;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  // @media (max-width: 767px) {
  //   min-height: 100%;
  // }
`;

const ArtworkTextContainer = styled.section`
  padding: 80px 0;
  max-width: 930px;

  @media (max-width: 767px) {
    padding: 60px 0;
  }
`;

const FooterArtworkTextContainer = styled.section`
  padding: 0 0 80px 0;

  & p {
    max-width: 700px;
    margin: 0;
    text-align: left;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;

  margin: 0 auto;
  padding: 150px 0 0 0;

  @media (max-width: 767px) {
    padding: 0;
  }
`;

const Background = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: -1;

  background-color: ${props => props.color};
  transition: 750ms background-color cubic-bezier(0.25, 0.46, 0.45, 0.94);
`;

const ArtworkWrapper = styled.div`
  & .artwork {
    margin: 0 auto 140px auto;

    @media (max-width: 767px) {
      margin: 0 auto 60px auto;
    }

    & p {
      font-size: 14px;
      line-height: 18px;

      text-align: center;
      color: #818181;
    }
  }
`;

const SplashSection = styled.div`
  align-self: flex-start;
  margin: 1px 0 0 auto;
  max-width: 600px;

  @media (max-width: 1010px) {
    margin: 75px auto 0 0;
  }
`;

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== "undefined";

const Index = ({ data: staticData }) => {
  const data = useMemo(() => {
    // If we're not in a browser (i.e. we're in SSR) or preview data has not been
    // set, use the non-preview static data.
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData;

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    });
  }, [staticData]);

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
  });

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      sal({
        threshold: 0.2,
        once: true,
      });
    }
  }, []);

  const artworks = data.prismicHomepage.data.artworks.map(
    (content, outerIndex) => (
      <div
        key={`homepage_artwork_${outerIndex}`}
        className="artwork"
        data-sal="fade"
        data-sal-duration="450"
        data-sal-easing="ease-out-quad"
      >
        <img
          src={content.image.url}
          alt={content.caption.text}
          loading="lazy"
        />
        <div dangerouslySetInnerHTML={{ __html: content.caption.html }} />
        <div
          className="coming-soon-text"
          dangerouslySetInnerHTML={{ __html: content.coming_soon_text.html }}
        />
      </div>
    )
  );

  return (
    <>
      <HeaderTextColorConsumer>
        {({ setHeaderTextColor }) => (
          <UpdateHeaderTextColor
            updatedHeaderTextColor={inView ? `#fff` : `#000`}
            setHeaderTextColor={setHeaderTextColor}
          />
        )}
      </HeaderTextColorConsumer>

      <Helmet title="A Delicate Sight" />
      <Page>
        <Section ref={ref}>
          <SplashSection>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: data.prismicHomepage.data.text.html,
              }}
            />
          </SplashSection>

          <Title />
          {typeof window !== undefined && (
            <DownArrow
              onClick={() =>
                window.scrollBy({
                  top: window.innerHeight,
                  left: 0,
                  behavior: "smooth",
                })
              }
            />
          )}
        </Section>

        <VideoWrapper
          data-sal="fade"
          data-sal-duration="450"
          data-sal-easing="ease-out-quad"
        >
          <Video
            url={data.prismicHomepage.data.video.html}
            id={`homepage_video`}
          />
        </VideoWrapper>

        <ArtworkWrapper>
          <ArtworkTextContainer>
            <div
              className="artwork-text"
              dangerouslySetInnerHTML={{
                __html: data.prismicHomepage.data.artworks_text.html,
              }}
            />
          </ArtworkTextContainer>

          {artworks}
        </ArtworkWrapper>

        <FooterArtworkTextContainer>
          <div
            className="artwork-text"
            dangerouslySetInnerHTML={{
              __html: data.prismicHomepage.data.footer_artworks_text.html,
            }}
          />
        </FooterArtworkTextContainer>
      </Page>
      <Background color={inView ? `#000` : `#fff`} />
    </>
  );
};

export default Index;

export const pageQuery = graphql`
  query Homepage {
    prismicHomepage {
      data {
        text {
          html
          text
        }
        video {
          html
        }
        artworks {
          caption {
            html
            text
          }
          image {
            url
          }
          coming_soon_text {
            html
          }
        }
        artworks_text {
          html
        }
        footer_artworks_text {
          html
        }
      }
    }
  }
`;
